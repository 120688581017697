/**
 * Calculates the slope of the line of best fit for a linear regression over the supplied metrics.
 */
export function trend(metrics: number[]): number | undefined {
  if (metrics.length < 2) return undefined
  const numIntervals = metrics.length
  const sumIntervals = metrics.reduce((prev: number, _current: number, index: number) => prev + index, 0)
  const squareIntervals = metrics.reduce((prev: number, _current: number, index: number) => prev + (Math.pow(index, 2)), 0)
  const sumMetrics = metrics.reduce((prev: number, current: number) => prev + current, 0)
  const productMetrics = metrics.reduce((prev: number, current: number, index: number) => prev + (current * index), 0)
  const intervalMean = sumIntervals / numIntervals
  const metricMean = sumMetrics / numIntervals
  return (productMetrics - (sumIntervals * metricMean)) / (squareIntervals - (sumIntervals * intervalMean))
}

/**
 * Calculates the percentage difference between the slope of the line of best fit and the average value
 */
export function trendAsPercentage(data: number[] | undefined): number | undefined {
  if (data?.length) {
    const trendLine = trend(data)
    const avg = data.reduce((sum, a) => sum + a, 0) / data.length
    const trendPercent = Math.floor(((trendLine ?? 0) / avg) * 100)
    return isNaN(trendPercent) ? 0 : trendPercent
  }
  return 0
}
