
import { computed, defineComponent, onMounted, ref, watch } from 'vue'

import { trendAsPercentage } from '@/helpers/analytics'
import ApiService from '@/services/ApiService'
import { useStore } from '@/store'
import CheckIn from '@/types/checkin'
import { CheckInAnalytics } from '@/types/checkinAnalytics'
import { BarChart, LineChart, PieChart } from 'echarts/charts'
import { GridComponent, LegendComponent, TitleComponent, TooltipComponent } from 'echarts/components'
import * as echarts from 'echarts/core'
import { LabelLayout, UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import VChart from 'vue-echarts'
import CheckInAnalyticsPanelSkeleton from '@skeleton/feature/check-in/CheckInAnalyticsPanel.vue'

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  BarChart,
  LineChart,
  CanvasRenderer,
  LabelLayout,
  GridComponent,
  UniversalTransition
])

export default defineComponent({
  name: 'CheckInAnalyticsPanel',
  components: {
    VChart,
    CheckInAnalyticsPanelSkeleton
  },
  setup() {
    const store = useStore()
    const showSkeletonView = ref(true)
    const checkIns = computed<CheckIn[]>(() => store.getters['checkin/getCheckIns'])
    const teamId = computed<string>(() => store.getters['user/getActiveMembership']?.teamId)
    const analytics = ref<CheckInAnalytics>()
    const canDisplayAnalytics = computed(() => {
      const cyclesWithData = analytics.value?.checkInMetrics.filter((e) => e.metrics.itemMetrics.items && e.metrics.itemMetrics.items > 0)
      return cyclesWithData !== undefined && cyclesWithData.length > 1
    })

    const avgResolveDays = computed(() => {
      const avgResolveSeconds = analytics.value?.metrics.itemMetrics.avgResolveSeconds
      if (avgResolveSeconds) {
        const days = Math.floor(avgResolveSeconds / 86400)
        return days > 0 ? `${days}d` : '1d'
      }
      return undefined
    })
    const themeExtremes = computed(() => {
      const themeMetrics = analytics.value?.metrics.themeMetrics
      if (themeMetrics) {
        themeMetrics.sort((a, b) => ((a.itemMetrics.positive ?? 0) + (a.itemMetrics.negative ?? 0)) - ((b.itemMetrics.positive ?? 0) + (b.itemMetrics.negative ?? 0)))
      }
      return themeMetrics?.slice(-10)
    })
    const mostPositiveThemeHighlights = computed(() => {
      const themeMetrics = analytics.value?.metrics.themeMetrics.filter((e) => e.itemMetrics.positive && e.itemMetrics.positive > 0)
      if (themeMetrics) {
        const copyOfThemeMetrics = [...themeMetrics]
        copyOfThemeMetrics.sort((a, b) => (a.itemMetrics.positive ?? 0) - (b.itemMetrics.positive ?? 0))
        return copyOfThemeMetrics?.map((e) => e.theme).slice(-3)
      }
      return undefined
    })
    const mostNegativeThemeHighlights = computed(() => {
      const themeMetrics = analytics.value?.metrics.themeMetrics.filter((e) => e.itemMetrics.negative && e.itemMetrics.negative > 0)
      if (themeMetrics) {
        const copyOfThemeMetrics = [...themeMetrics]
        copyOfThemeMetrics.sort((a, b) => (a.itemMetrics.negative ?? 0) - (b.itemMetrics.negative ?? 0))
        return copyOfThemeMetrics?.map((e) => e.theme).slice(-3)
      }
      return undefined
    })
    const themePerformance = computed(() => {
      return themeExtremes.value?.map((theme) => {
        const data = analytics.value?.checkInMetrics.map((e) => {
          const themeMetricsForCycle = e.metrics.themeMetrics.find((t) => t.theme === theme.theme)
          return {
            positive: themeMetricsForCycle?.itemMetrics.positive ?? 0,
            negative: themeMetricsForCycle?.itemMetrics.negative ?? 0,
            other: themeMetricsForCycle?.itemMetrics.other ?? 0
          }
        })
        return { theme: theme.theme, data }
      })
    })
    const actionWaterfall = computed(() => {
      const actioned: number[] = []
      const resolved: number[] = []
      let suspension: number[] = []
      if (analytics.value) {
        let offset = 0
        analytics.value.checkInMetrics.map((e) => e.metrics.itemMetrics).forEach((metrics) => {
          const actions = metrics.actionItems ?? 0
          const resolutions = metrics.resolvedItems ?? 0
          actioned.push(actions, 0)
          resolved.push(0, resolutions)
          suspension.push(offset, offset += (actions - resolutions))
        })
      }
      const minSuspension = Math.min(...suspension)
      if (minSuspension < 0) {
        const negCorrect = Math.abs(minSuspension)
        suspension = suspension.map((e) => e + negCorrect)
      }
      return {
        actioned,
        resolved,
        suspension
      }
    })
    const positiveNotesTrend = computed(() => trendAsPercentage(analytics.value?.checkInMetrics.map((e) => e.metrics.itemMetrics.positive ?? 0)))
    const negativeNotesTrend = computed(() => trendAsPercentage(analytics.value?.checkInMetrics.map((e) => e.metrics.itemMetrics.negative ?? 0)))
    const otherNotesTrend = computed(() => trendAsPercentage(analytics.value?.checkInMetrics.map((e) => e.metrics.itemMetrics.other ?? 0)))
    const actionsCreatedTrend = computed(() => trendAsPercentage(analytics.value?.checkInMetrics.map((e) => e.metrics.itemMetrics.actionItems ?? 0)))
    const actionsResolvedTrend = computed(() => trendAsPercentage(analytics.value?.checkInMetrics.map((e) => e.metrics.itemMetrics.resolvedItems ?? 0)))
    const avgResolutionTimeTrend = computed(() => {
      const avgResolveTimes = analytics.value?.checkInMetrics.map((e) => e.metrics.itemMetrics.avgResolveSeconds).filter((e): e is number => e !== undefined)
      return trendAsPercentage(avgResolveTimes)
    })

    const notesOptions = computed(() => ({
      title: {
        text: 'Notes',
        textStyle: {
          color: '#090909',
          fontFamily: '"Gilroy", ui-sans-serif',
          fontWeight: '700',
          fontSize: '22px'
        }
      },
      tooltip: {
        trigger: 'item'
      },
      color: ['#5D44B6', '#E333A7', '#DFDFDF'],
      series: [
        {
          top: 'top',
          name: 'Sentiment',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'center',
            formatter: () => {
              return `{a|${analytics.value?.metrics.itemMetrics.items}} \n {b|notes created}`
            },
            rich: {
              a: {
                fontSize: 36,
                fontStyle: 'bold'
              },
              b: {
                fontSize: 10,
                color: '#979797'
              }
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { value: analytics.value?.metrics.itemMetrics.positive, name: 'Positive' },
            { value: analytics.value?.metrics.itemMetrics.negative, name: 'Negative' },
            { value: analytics.value?.metrics.itemMetrics.other, name: 'Other' }
          ]
        }
      ]
    }))

    const actionOptions = computed(() => ({
      title: {
        text: 'Action items',
        textStyle: {
          color: '#090909',
          fontFamily: '"Gilroy", ui-sans-serif',
          fontWeight: '700',
          fontSize: '22px'
        }
      },
      tooltip: {
        trigger: 'item'
      },
      color: ['#404040', '#5D44B6'],
      series: [
        {
          top: 'top',
          name: 'Status',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'center',
            formatter: () => {
              return `{a|${(analytics.value?.metrics.itemMetrics.actionItems ?? 0) + (analytics.value?.metrics.itemMetrics.resolvedItems ?? 0)}} \n {b|actions created}`
            },
            rich: {
              a: {
                fontSize: 36,
                fontStyle: 'bold'
              },
              b: {
                fontSize: 10,
                color: '#979797'
              }
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { value: analytics.value?.metrics.itemMetrics.actionItems, name: 'Action Required' },
            { value: analytics.value?.metrics.itemMetrics.resolvedItems, name: 'Resolved' }
          ]
        }
      ]
    }))

    const actionWaterfallOptions = computed(() => ({
      title: {
        text: 'Action items',
        textStyle: {
          color: '#090909',
          fontFamily: '"Gilroy", ui-sans-serif',
          fontWeight: '700',
          fontSize: '22px'
        }
      },
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        bottom: 25
      },
      color: ['#404040', '#5D44B6'],
      xAxis: [
        {
          type: 'category',
          show: false,
          data: actionWaterfall.value?.actioned.map(() => 'Burndown')
        }
      ],
      yAxis: [
        {
          type: 'value',
          show: false
        }
      ],
      series: [
        {
          name: 'Cumulative',
          stack: 'Burndown',
          type: 'bar',
          itemStyle: {
            borderColor: 'rgba(0,0,0,0)',
            color: 'rgba(0,0,0,0)'
          },
          data: actionWaterfall.value?.suspension
        },
        {
          name: 'Actions created',
          stack: 'Burndown',
          type: 'bar',
          barCategoryGap: '5%',
          data: actionWaterfall.value?.actioned
        },
        {
          name: 'Actions resolved',
          stack: 'Burndown',
          type: 'bar',
          data: actionWaterfall.value?.resolved
        }
      ]
    }))

    const engagementOptions = computed(() => ({
      title: {
        text: 'Engagement over time',
        textStyle: {
          color: '#090909',
          fontFamily: '"Gilroy", ui-sans-serif',
          fontWeight: '700',
          fontSize: '22px'
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '30px',
        right: '10px',
        bottom: 25
      },
      color: ['#5D44B6', '#E333A7', '#DFDFDF'],
      xAxis: [
        {
          type: 'category',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          data: analytics.value?.checkInMetrics.map((e) => `Cycle ${e.checkInId}`),
          axisLabel: {
            color: '#979797'
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#979797'
            }
          }
        }
      ],
      series: [
        {
          name: 'Positive',
          stack: 'Sentiment',
          type: 'bar',
          barWidth: 20,
          emphasis: {
            focus: 'series'
          },
          data: analytics.value?.checkInMetrics.map((e) => e.metrics.itemMetrics.positive)
        },
        {
          name: 'Negative',
          stack: 'Sentiment',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          data: analytics.value?.checkInMetrics.map((e) => e.metrics.itemMetrics.negative)
        },
        {
          name: 'Other',
          stack: 'Sentiment',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          data: analytics.value?.checkInMetrics.map((e) => e.metrics.itemMetrics.other)
        }
      ]
    }))

    const themeExtremeOptions = computed(() => ({
      title: {
        text: 'Theme extremes',
        textStyle: {
          color: '#090909',
          fontFamily: '"Gilroy", ui-sans-serif',
          fontWeight: '700',
          fontSize: '22px'
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        bottom: 0
      },
      color: ['#5D44B6', '#E333A7', '#DFDFDF'],
      xAxis: [
        {
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        }
      ],
      yAxis: [
        {
          type: 'category',
          data: themeExtremes.value?.map((e) => e.theme),
          axisLabel: {
            color: '#979797'
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        }
      ],
      series: [
        {
          name: 'Positive',
          stack: 'Sentiment',
          type: 'bar',
          barWidth: 20,
          emphasis: {
            focus: 'series'
          },
          data: themeExtremes.value?.map((e) => e.itemMetrics.positive)
        },
        {
          name: 'Negative',
          stack: 'Sentiment',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          data: themeExtremes.value?.map((e) => (e.itemMetrics.negative ?? 0) * -1)
        }
      ]
    }))

    const themePerformanceOptions = computed(() => ({
      title: {
        text: 'Theme performance over time',
        textStyle: {
          color: '#090909',
          fontFamily: '"Gilroy", ui-sans-serif',
          fontWeight: '700',
          fontSize: '22px'
        }
      },
      legend: {
        data: themePerformance.value?.map((e) => e.theme),
        bottom: 0,
        icon: 'pin'
      },
      tooltip: {
        trigger: 'axis'
      },
      grid: {
        left: '30px',
        right: '30px',
        bottom: 40,
        containLabel: true
      },
      color: ['#0998c1', '#fdac32', '#7769e4', '#e27070', '#A20DFE', '#3ACAA2', '#FE599A', '#468DFE', '#923C29', '#078C68'],
      xAxis: [
        {
          type: 'category',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          data: analytics.value?.checkInMetrics.map((e) => `Cycle ${e.checkInId}`),
          axisLabel: {
            color: '#979797'
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#979797'
            }
          }
        }
      ],
      series: themePerformance.value?.map((theme) => ({
        name: theme.theme,
        type: 'line',
        data: theme.data?.map((e) => e.positive - e.negative)
      }))
    }))

    watch([teamId, checkIns], async() => {
      await getCheckInAnalytics()
    })

    onMounted(async() => {
      await getCheckInAnalytics()
    })

    async function getCheckInAnalytics(): Promise<void> {
      if (teamId.value && checkIns.value.length) {
        showSkeletonView.value = true
        const latestCheckIn = checkIns.value[0].id
        const response = await ApiService.sendRequest(`teams/${teamId.value}/check-in-analytics/${latestCheckIn}`, {
          method: 'GET',
          requiresAuth: true
        })
        analytics.value = await response.json()
        showSkeletonView.value = false
      }
    }

    return {
      analytics,
      canDisplayAnalytics,
      avgResolveDays,
      showSkeletonView,
      teamId,
      notesOptions,
      actionOptions,
      engagementOptions,
      themeExtremeOptions,
      actionWaterfall,
      actionWaterfallOptions,
      themeExtremes,
      themePerformanceOptions,
      positiveNotesTrend,
      negativeNotesTrend,
      otherNotesTrend,
      actionsCreatedTrend,
      actionsResolvedTrend,
      avgResolutionTimeTrend,
      mostPositiveThemeHighlights,
      mostNegativeThemeHighlights
    }
  }
})
