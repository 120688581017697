<template>
  <div class="grid check-in-analytics-panel loading">
    <div class="app-panel col-md-6 col-lg-4 col-12 check-in-analytics-panel__notes-panel">
      <div class="check-in-analytics-panel__notes-panel__chart">
        <div class="circle"></div>
      </div>
      <div
        class="
          check-in-analytics-panel__notes-panel__grid
          grid
          align-items-center
        "
      >
        <span class="col-4 note-count dark"></span
        ><span class="col-4 note-count positive"></span
        ><span class="col-4 note-count dark"></span
        ><span class="col-4 sentiment-label"></span
        ><span class="col-4 sentiment-label"></span
        ><span class="col-4 sentiment-label"></span
        ><span class="col-4 trend-label black-trend"></span
        ><span class="col-4 trend-label black-trend"></span
        ><span class="col-4 trend-label black-trend"></span>
      </div>
    </div>
    <div class="app-panel col-md-6 col-lg-4 col-12 check-in-analytics-panel__notes-panel">
      <div class="check-in-analytics-panel__notes-panel__chart">
        <div class="circle"></div>
      </div>
      <div
        class="
          check-in-analytics-panel__notes-panel__grid
          grid
          align-items-center
        "
      >
        <span class="col-4 note-count dark"></span
        ><span class="col-4 note-count positive"></span
        ><span class="col-4 note-count dark"></span
        ><span class="col-4 sentiment-label"></span
        ><span class="col-4 sentiment-label"></span
        ><span class="col-4 sentiment-label"></span
        ><span class="col-4 trend-label black-trend"></span
        ><span class="col-4 trend-label black-trend"></span
        ><span class="col-4 trend-label black-trend"></span>
      </div>
    </div>

    <div class="app-panel col-md-6 col-lg-4 col-12 check-in-analytics-panel__notes-panel">
      <div class="check-in-analytics-panel__notes-panel__chart">
        <div class="circle"></div>
      </div>
      <div
        class="
          check-in-analytics-panel__notes-panel__grid
          grid
          align-items-center
        "
      >
        <span class="col-4 note-count dark"></span
        ><span class="col-4 note-count positive"></span
        ><span class="col-4 note-count dark"></span
        ><span class="col-4 sentiment-label"></span
        ><span class="col-4 sentiment-label"></span
        ><span class="col-4 sentiment-label"></span
        ><span class="col-4 trend-label black-trend"></span
        ><span class="col-4 trend-label black-trend"></span
        ><span class="col-4 trend-label black-trend"></span>
      </div>
      <!--v-if-->
    </div>

    <div class="app-panel col-md-6 col-12 check-in-analytics-panel__notes-panel">
      <div class="check-in-analytics-panel__notes-panel__chart">
        <div class="circle"></div>
      </div>
      <div
        class="
          check-in-analytics-panel__notes-panel__grid
          grid
          align-items-center
        "
      >
        <span class="col-4 note-count dark"></span
        ><span class="col-4 note-count positive"></span
        ><span class="col-4 note-count dark"></span
        ><span class="col-4 sentiment-label"></span
        ><span class="col-4 sentiment-label"></span
        ><span class="col-4 sentiment-label"></span
        ><span class="col-4 trend-label black-trend"></span
        ><span class="col-4 trend-label black-trend"></span
        ><span class="col-4 trend-label black-trend"></span>
      </div>
    </div>

      <div class="app-panel col-lg-6 col-12 check-in-analytics-panel__notes-panel">
      <div class="check-in-analytics-panel__notes-panel__chart">
        <div class="circle"></div>
      </div>
      <div
        class="
          check-in-analytics-panel__notes-panel__grid
          grid
          align-items-center
        "
      >
        <span class="col-4 note-count dark"></span
        ><span class="col-4 note-count positive"></span
        ><span class="col-4 note-count dark"></span
        ><span class="col-4 sentiment-label"></span
        ><span class="col-4 sentiment-label"></span
        ><span class="col-4 sentiment-label"></span
        ><span class="col-4 trend-label black-trend"></span
        ><span class="col-4 trend-label black-trend"></span
        ><span class="col-4 trend-label black-trend"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Skeleton:CheckInAnalyticsPanel'
})
</script>

<style lang="scss">
.check-in-analytics-panel.loading {
  .note-count,
  .circle,
  .sentiment-label,
  .trend-label {
    background: linear-gradient(
      110deg,
      var(--color-grey-200) 8%,
      var(--color-grey-100) 18%,
      var(--color-grey-200) 33%
    );
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  .check-in-analytics-panel__notes-panel__chart {
    display: flex;
    justify-content: center;
  }

  .circle {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 auto;
  }

  .note-count,
  .sentiment-label,
  .trend-label {
    display: inline-block;
    margin: 5px auto 0;
    width: 115px !important;
    height: 30px;
  }
}
</style>
